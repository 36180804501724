import { Text } from "@chakra-ui/react";
import { useNumberFormatter } from "src/hooks/formatters/use-number-formatter";
import { getPerformanceColor } from "src/utils/color-util";

export enum EnumLeaderBoardFigureValueType {
    Performance = "performance",
    MaxLoss = "maxLoss",
    Risk = "risk",
    Herfindahl = "herfindahl",
}

interface ILeaderBoardFigureValueProps {
    value: number;
    type: EnumLeaderBoardFigureValueType;
}

const HERFINDAHL_COLOR_THRESHOLD = 0.3;
export const LeaderBoardFigureValue = ({ value, type }: ILeaderBoardFigureValueProps) => {
    const { formatPerformance, formatPercent, formatNumber } = useNumberFormatter();

    let valueToDisplay = "-";
    let color: string | undefined;
    switch (type) {
        case EnumLeaderBoardFigureValueType.Performance:
            valueToDisplay = formatPerformance(value, 1);
            color = getPerformanceColor(valueToDisplay);
            break;
        case EnumLeaderBoardFigureValueType.MaxLoss:
            valueToDisplay = formatPercent(value, 1);
            break;
        case EnumLeaderBoardFigureValueType.Risk:
            valueToDisplay = formatNumber(value, 2);
            break;
        case EnumLeaderBoardFigureValueType.Herfindahl:
            valueToDisplay = formatNumber(value, 2);
            if (value > HERFINDAHL_COLOR_THRESHOLD) {
                color = "red.600";
            }
            break;
    }

    return <Text color={color}>{valueToDisplay}</Text>;
};
