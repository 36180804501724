import { Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { WfHeading } from "src/components/base/wf-heading";
import { LeaderBoardTableComponent } from "src/components/modules/leader-board-module/components/leader-board-table";
import { ILeaderBoardData } from "src/components/modules/leader-board-module/leader-board-module.types";

export interface ILeaderBoardModuleProps {
    title: string;
    description: string;
    firstTabLabel?: string;
    secondTabLabel?: string;
    thirdTabLabel?: string;
    fourthTabLabel?: string;
    firstColumnTooltip?: string;
    secondColumnTooltip?: string;
    thirdColumnTooltip?: string;
    fourthColumnTooltip?: string;
    fifthColumnTooltip?: string;
    clubs: string[];
    data?: ILeaderBoardData[];
}

export const LeaderBoardModule = ({
    title,
    description,
    firstTabLabel,
    secondTabLabel,
    thirdTabLabel,
    fourthTabLabel,
    firstColumnTooltip,
    secondColumnTooltip,
    thirdColumnTooltip,
    fourthColumnTooltip,
    fifthColumnTooltip,
    data,
    clubs,
}: ILeaderBoardModuleProps) => {
    return (
        <>
            <WfHeading as="h2" fontSize="4xl">
                {title}
            </WfHeading>
            <Text fontSize="lg" mt={2} mb={7}>
                {description}
            </Text>
            <Tabs variant="pills" mt={2}>
                <TabList border="none">
                    <Tab key="p1">{firstTabLabel}</Tab>
                    <Tab key="p2">{secondTabLabel}</Tab>
                    <Tab key="p3">{thirdTabLabel}</Tab>
                    <Tab key="p4">{fourthTabLabel}</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel key="p1">
                        <LeaderBoardTableComponent
                            firstColumnTooltip={firstColumnTooltip}
                            secondColumnTooltip={secondColumnTooltip}
                            thirdColumnTooltip={thirdColumnTooltip}
                            fourthColumnTooltip={fourthColumnTooltip}
                            fifthColumnTooltip={fifthColumnTooltip}
                            clubs={clubs}
                            data={data}
                        />
                    </TabPanel>
                    <TabPanel key="p2">
                        <LeaderBoardTableComponent
                            firstColumnTooltip={firstColumnTooltip}
                            secondColumnTooltip={secondColumnTooltip}
                            thirdColumnTooltip={thirdColumnTooltip}
                            fourthColumnTooltip={fourthColumnTooltip}
                            fifthColumnTooltip={fifthColumnTooltip}
                            clubs={clubs}
                            data={data}
                        />
                    </TabPanel>
                    <TabPanel key="p3">
                        <LeaderBoardTableComponent
                            firstColumnTooltip={firstColumnTooltip}
                            secondColumnTooltip={secondColumnTooltip}
                            thirdColumnTooltip={thirdColumnTooltip}
                            fourthColumnTooltip={fourthColumnTooltip}
                            fifthColumnTooltip={fifthColumnTooltip}
                            clubs={clubs}
                            data={data}
                        />
                    </TabPanel>
                    <TabPanel key="p4">
                        <LeaderBoardTableComponent
                            firstColumnTooltip={firstColumnTooltip}
                            secondColumnTooltip={secondColumnTooltip}
                            thirdColumnTooltip={thirdColumnTooltip}
                            fourthColumnTooltip={fourthColumnTooltip}
                            fifthColumnTooltip={fifthColumnTooltip}
                            clubs={clubs}
                            data={data}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};
