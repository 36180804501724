import { Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useDateTimeFormatter } from "src/hooks/formatters/use-date-time-formatter";

interface IChallengeFormDate {
    isRegistrationActive: boolean;
    isLastPeriodOngoing: boolean;
    registrationStartDate: string;
    registrationEndDate: string;
}

export const ChallengeDate = ({ isRegistrationActive, isLastPeriodOngoing, registrationStartDate, registrationEndDate }: IChallengeFormDate) => {
    const { t } = useTranslation("challenge-form-module");
    const { formatDateShort, formatTimeShort } = useDateTimeFormatter();

    const formatRegistrationDate = () => {
        if (isLastPeriodOngoing) {
            return t("registration-text.registration-not-possible");
        }

        if (isRegistrationActive) {
            return t("registration-text.registration-open", {
                0: formatDateShort(registrationEndDate),
                1: formatTimeShort(new Date(registrationEndDate)),
            });
        }

        return t("registration-text.registration-open-at", {
            0: formatDateShort(registrationStartDate),
            1: formatTimeShort(new Date(registrationStartDate)),
        });
    };

    return (
        <Text textAlign="left" fontSize="md">
            {formatRegistrationDate()}
        </Text>
    );
};
