import { ComboBox, ComboBoxItem } from "src/components/base/combo-box";

interface IChallengeDropdownProps {
    options?: string[];
    isDisabled: boolean;
    value: string | null;
    onChange: (value: string) => void;
    placeholder: string;
    emptyText: string;
}

export const LeaderBoardDropdown = ({ options, isDisabled, value, onChange, placeholder, emptyText }: IChallengeDropdownProps) => (
    <ComboBox
        isDisabled={isDisabled}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        inputBoxProps={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "block",
            overflow: "hidden",
            textAlign: "left",
            pr: 3,
        }}
        menuListProps={{
            maxH: 256,
            width: 327,
            zIndex: "dropdown",
        }}
    >
        {options && options.length
            ? options.map((option, index) => (
                  <ComboBoxItem
                      key={index}
                      value={1}
                      sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", display: "inherit", overflow: "hidden" }}
                      h={5}
                  >
                      {option}
                  </ComboBoxItem>
              ))
            : [
                  <ComboBoxItem
                      key={1}
                      value={1}
                      sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", display: "inherit", overflow: "hidden" }}
                      h={5}
                      isDisabled={true}
                  >
                      {emptyText}
                  </ComboBoxItem>,
              ]}
    </ComboBox>
);
