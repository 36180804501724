import { createChallengeFormSubmitUrl } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";

interface IChallengeFormRequest {
    wikifolioId: string;
    organisationId: string;
}

export const useChallengeFormMutation = (challengeId: string) =>
    useMutation<void, unknown, IChallengeFormRequest>(createChallengeFormSubmitUrl(challengeId), { method: "PUT" });
