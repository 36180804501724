import { Badge, Flex, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfLinkGhost } from "src/components/base/wf-link";
import { TraderAvatar } from "src/components/common/trader-avatar";
import { TableBorderShadowShell } from "src/components/table-scrollable/components/table-border-shadow-shell";
import { TableStickyCellAnimateContainer } from "src/components/table-scrollable/components/table-sticky-cell-animate-container";
import { useGetUserName } from "src/hooks/use-get-user-name";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { IUser } from "src/types/common";

interface ILeaderBoardTraderCellProps {
    trader: Pick<IUser, "id" | "firstName" | "lastName" | "nickName" | "isDeleted">;
    symbol: string;
    wikifolioShortDescription: string;
    clubName: string;
    isCurrentUserWikifolio?: boolean;
}

export const LeaderBoardTraderCell = ({
    trader,
    wikifolioShortDescription,
    symbol,
    clubName,
    isCurrentUserWikifolio,
}: ILeaderBoardTraderCellProps) => {
    const { t } = useTranslation("leader-board-module");
    const getUserName = useGetUserName();
    const { buildTraderProfileLink, buildWikifolioLink } = useLinkBuilder();

    const traderProfileLink = buildTraderProfileLink(trader.nickName);
    const wikifolioLink = buildWikifolioLink(symbol);
    const traderName = getUserName(trader);

    return (
        <TableBorderShadowShell>
            <TableStickyCellAnimateContainer>
                <HStack marginRight="auto" spacing={1} overflow="hidden" order={0}>
                    <TraderAvatar size="sm" zIndex={1} trader={trader} href={traderProfileLink} />
                    <Flex flexDir="column" flexGrow={0} overflow="hidden">
                        <WfLinkGhost w="100%" display="inline-flex" alignItems="center" href={wikifolioLink}>
                            <Text minW="42px" as="span" overflow="hidden" textOverflow="ellipsis" mr={1}>
                                {wikifolioShortDescription}
                            </Text>
                            {isCurrentUserWikifolio && (
                                <Badge flexShrink={0} size="xs">
                                    {t("table.my-wikifolio")}
                                </Badge>
                            )}
                        </WfLinkGhost>
                        <WfLinkGhost
                            fontWeight="normal"
                            fontSize="sm"
                            href={traderProfileLink}
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                        >
                            {traderName}
                        </WfLinkGhost>
                        <Text textOverflow="ellipsis" overflow="hidden">
                            {clubName}
                        </Text>
                    </Flex>
                </HStack>
            </TableStickyCellAnimateContainer>
        </TableBorderShadowShell>
    );
};
