import { ComboBox, ComboBoxItem } from "src/components/base/combo-box";
import { IChallengeOrganisation, TChallengeWikifolio } from "src/components/modules/challenge-form-module/challenge-form-module.types";

interface IChallengeDropdownProps {
    options?: TChallengeWikifolio[] | IChallengeOrganisation[];
    isDisabled: boolean;
    value?: TChallengeWikifolio | IChallengeOrganisation;
    onChange: (value: TChallengeWikifolio | IChallengeOrganisation) => void;
    placeholder: string;
    emptyText?: string;
}

export const ChallengeDropdown = ({ value, options, isDisabled, onChange, placeholder, emptyText }: IChallengeDropdownProps) => (
    <ComboBox
        isDisabled={isDisabled}
        onChange={onChange}
        value={value ?? null}
        placeholder={placeholder}
        inputBoxProps={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "block",
            overflow: "hidden",
            textAlign: "left",
            pr: 3,
        }}
        menuListProps={{
            maxH: 256,
            width: ["calc(100vw - 48px)", 620],
        }}
    >
        {options && options.length
            ? options.map((option, index) => (
                  <ComboBoxItem
                      key={index}
                      value={option}
                      sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", display: "inherit", overflow: "hidden" }}
                      h={5}
                  >
                      {(option as TChallengeWikifolio)?.shortDescription ?? (option as IChallengeOrganisation).name}
                  </ComboBoxItem>
              ))
            : [
                  <ComboBoxItem
                      key={1}
                      value={1}
                      sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap", display: "inherit", overflow: "hidden" }}
                      h={5}
                      isDisabled={true}
                  >
                      {emptyText}
                  </ComboBoxItem>,
              ]}
    </ComboBox>
);
