import { useState } from "react";
import { Box } from "@chakra-ui/react";
import { PaginationState } from "@tanstack/react-table";
import { useTranslation } from "next-i18next";
import { LeaderBoardDropdown } from "src/components/modules/leader-board-module/components/leader-board-dropdown";
import { makeLeaderBoardColumns } from "src/components/modules/leader-board-module/leader-board-columns";
import { ILeaderBoardData } from "src/components/modules/leader-board-module/leader-board-module.types";
import { TablePagination } from "src/components/table-scrollable/components/table-pagination";
import { TableScrollable } from "src/components/table-scrollable/table-scrollable";

interface ILeaderBoardTableComponentProps {
    firstColumnTooltip?: string;
    secondColumnTooltip?: string;
    thirdColumnTooltip?: string;
    fourthColumnTooltip?: string;
    fifthColumnTooltip?: string;
    clubs: string[];
    data?: ILeaderBoardData[];
}

const PAGE_SIZE_OPTIONS = [10, 25, 50];

export const LeaderBoardTableComponent = ({
    data,
    clubs,
    firstColumnTooltip,
    secondColumnTooltip,
    thirdColumnTooltip,
    fourthColumnTooltip,
    fifthColumnTooltip,
}: ILeaderBoardTableComponentProps) => {
    const { t } = useTranslation("leader-board-module");
    const columns = makeLeaderBoardColumns({
        t,
        firstColumnTooltip,
        secondColumnTooltip,
        thirdColumnTooltip,
        fourthColumnTooltip,
        fifthColumnTooltip,
    });
    const [pagination, setPagination] = useState<PaginationState>({ pageSize: PAGE_SIZE_OPTIONS[0], pageIndex: 0 });
    const [dropdownValue, setDropdownValue] = useState<string | null>(null);

    return (
        <>
            <Box maxWidth="327px" mb={1}>
                <LeaderBoardDropdown
                    isDisabled={false}
                    value={dropdownValue}
                    options={clubs}
                    onChange={setDropdownValue}
                    placeholder={t("dropdown.placeholder")}
                    emptyText={"dropdown.empty"}
                />
            </Box>
            <TableScrollable
                data={data ?? []}
                columns={columns}
                pagination={pagination}
                onPaginationChange={setPagination}
                manualPagination={true}
                manualSorting={true}
                rowCount={data?.length || 0}
                rowStyle={{
                    "&:hover ": {
                        backgroundColor: "gray.50",
                    },
                }}
                paginationRender={table => (
                    <TablePagination
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        pageSize={table.getState().pagination.pageSize}
                        itemsCount={data?.length || 0}
                        onPageSizeChange={table.setPageSize}
                        currentPageIndex={table.getState().pagination.pageIndex}
                        pageIndexes={table.getPageOptions()}
                        onPageSelect={table.setPageIndex}
                        onPreviousClick={table.previousPage}
                        onNextClick={table.nextPage}
                        canGoPreviousPage={table.getCanPreviousPage()}
                        canGoNextPage={table.getCanNextPage()}
                        isLoading={false}
                        itemsLabel={t("table.items")}
                    />
                )}
            />
        </>
    );
};
