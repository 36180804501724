import { ReactElement } from "react";
// eslint-disable-next-line no-restricted-imports
import { Box, Flex, FlexProps, Td, Text, Th } from "@chakra-ui/react";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { TFunction } from "next-i18next";
import { WfTooltip } from "src/components/base/wf-tooltip";
import { IconInfo } from "src/components/icons";
import {
    EnumLeaderBoardFigureValueType,
    LeaderBoardFigureValue,
} from "src/components/modules/leader-board-module/components/leader-board-figure-value";
import { LeaderBoardTraderCell } from "src/components/modules/leader-board-module/components/leader-board-trader-cell";
import { ILeaderBoardData, ILeaderBoardTrader } from "src/components/modules/leader-board-module/leader-board-module.types";
import { TABLE_HEADER_ELEMENT_HEIGHT } from "src/components/table-scrollable/components/constants";
import { TableRowCell } from "src/components/table-scrollable/components/table-row-cell";

const CURRENT_USER_HIGHLIGHT_COLOR = "green.50";

interface ITableHeaderCellProps {
    children: ReactElement;
    tooltip?: string;
}
const TableHeaderWithTooltip = ({ children, tooltip }: ITableHeaderCellProps) => (
    <Flex alignItems="flex-start">
        <Box mr={0.5}>{children}</Box>
        <WfTooltip content={tooltip ?? ""} aria-label={tooltip} contentProps={{ maxWidth: "320px", w: "auto" }}>
            <Box>
                <IconInfo fill="gray.100" color="grey.800" boxSize={2} />
            </Box>
        </WfTooltip>
    </Flex>
);

interface ITableValueProps extends FlexProps {
    traderColumn: ILeaderBoardTrader;
}
const TableValue = ({ children, traderColumn, ...props }: ITableValueProps) => {
    return (
        <Flex
            w="100%"
            h="100%"
            px={1}
            justifyContent="flex-end"
            alignItems="center"
            backgroundColor={traderColumn.isCurrentUserWikifolio ? CURRENT_USER_HIGHLIGHT_COLOR : "white"}
            fontWeight={traderColumn.isCurrentUserWikifolio ? "bold" : "normal"}
            {...props}
        >
            {children}
        </Flex>
    );
};

interface IMakeLeaderBoardColumnsProps {
    t: TFunction;
    firstColumnTooltip?: string;
    secondColumnTooltip?: string;
    thirdColumnTooltip?: string;
    fourthColumnTooltip?: string;
    fifthColumnTooltip?: string;
}

export const makeLeaderBoardColumns = ({
    t,
    firstColumnTooltip,
    secondColumnTooltip,
    thirdColumnTooltip,
    fourthColumnTooltip,
    fifthColumnTooltip,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
IMakeLeaderBoardColumnsProps): ColumnDef<ILeaderBoardData, any>[] => {
    const columnHelper = createColumnHelper<ILeaderBoardData>();

    const columns = [
        columnHelper.accessor("trader", {
            header: ({ header }) => {
                return (
                    <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        height={TABLE_HEADER_ELEMENT_HEIGHT}
                        border="none"
                        padding={0}
                        backgroundColor="white"
                        zIndex={1}
                        position="sticky"
                        left="0"
                        _hover={{ color: "gray.600" }}
                    >
                        <TableRowCell header={header} height="100%" paddingLeft={[0, 0, 2]}>
                            <Box
                                position="absolute"
                                h="100%"
                                top={0}
                                left={0}
                                w="100%"
                                maxW={["229px", "229px", "unset"]}
                                borderRightWidth={["2px", "2px", 0, 0]}
                                borderColor="gray.100"
                            />
                        </TableRowCell>
                    </Th>
                );
            },
            cell: info => {
                return (
                    <Td
                        height={["64px", "64px", "64px", "100%"]}
                        borderWidth={0}
                        padding={0}
                        position="sticky"
                        left="0"
                        backgroundColor={info.getValue().isCurrentUserWikifolio ? CURRENT_USER_HIGHLIGHT_COLOR : "white"}
                        zIndex={1}
                        key={info.cell.id}
                    >
                        <LeaderBoardTraderCell {...info.cell.getValue()} />
                    </Td>
                );
            },
            enableSorting: false,
            meta: { customTd: true, customTh: true },
        }),
        columnHelper.accessor("place", {
            id: "place",
            header: ({ header }) => (
                <TableRowCell header={header}>
                    <TableHeaderWithTooltip tooltip={firstColumnTooltip}>
                        <>
                            <Text fontSize="sm" as="span">
                                {t("table.headers.place")}
                            </Text>
                            <Text textAlign="left" fontWeight="normal" fontSize="sm">
                                {t("table.headers.score")}
                            </Text>
                        </>
                    </TableHeaderWithTooltip>
                </TableRowCell>
            ),
            cell: info => (
                <TableValue traderColumn={info.row.getValue("trader")} pr={1}>
                    <Box>
                        <Text as="p" fontWeight="bold">
                            {t("table.values.place", { 0: info.getValue().place })}
                        </Text>
                        <Text as="p" fontWeight="normal">
                            {t("table.values.score", { 0: info.getValue().score })}
                        </Text>
                    </Box>
                </TableValue>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor("performance", {
            header: ({ header }) => (
                <TableRowCell header={header}>
                    <TableHeaderWithTooltip tooltip={secondColumnTooltip}>
                        <Text fontSize="sm" as="span" whiteSpace="pre" mr={0.5}>
                            {t("table.headers.performance")}
                        </Text>
                    </TableHeaderWithTooltip>
                </TableRowCell>
            ),
            cell: info => {
                const rankingData = info.renderValue();
                return rankingData !== null ? (
                    <TableValue traderColumn={info.row.getValue("trader")} pr={1}>
                        <LeaderBoardFigureValue value={rankingData} type={EnumLeaderBoardFigureValueType.Performance} />
                    </TableValue>
                ) : null;
            },
        }),
        columnHelper.accessor("loss", {
            header: ({ header }) => (
                <TableRowCell header={header}>
                    <TableHeaderWithTooltip tooltip={thirdColumnTooltip}>
                        <Text fontSize="sm" as="span" whiteSpace="pre" mr={0.5}>
                            {t("table.headers.max-loss")}
                        </Text>
                    </TableHeaderWithTooltip>
                </TableRowCell>
            ),
            cell: info => {
                const rankingData = info.renderValue();
                return rankingData !== null ? (
                    <TableValue traderColumn={info.row.getValue("trader")} pr={1}>
                        <LeaderBoardFigureValue value={rankingData} type={EnumLeaderBoardFigureValueType.MaxLoss} />
                    </TableValue>
                ) : null;
            },
        }),
        columnHelper.accessor("risk", {
            header: ({ header }) => (
                <TableRowCell header={header}>
                    <TableHeaderWithTooltip tooltip={fourthColumnTooltip}>
                        <Text fontSize="sm" as="span" whiteSpace="pre" mr={0.5}>
                            {t("table.headers.risk")}
                        </Text>
                    </TableHeaderWithTooltip>
                </TableRowCell>
            ),
            cell: info => {
                const rankingData = info.renderValue();
                return rankingData !== null ? (
                    <TableValue traderColumn={info.row.getValue("trader")} pr={1}>
                        <LeaderBoardFigureValue value={rankingData} type={EnumLeaderBoardFigureValueType.Risk} />
                    </TableValue>
                ) : null;
            },
        }),
        columnHelper.accessor("herfindahl", {
            header: ({ header }) => (
                <TableRowCell header={header} pr={1}>
                    <TableHeaderWithTooltip tooltip={fifthColumnTooltip}>
                        <Text fontSize="sm" as="span" whiteSpace="pre" mr={0.5}>
                            {t("table.headers.herfindahl")}
                        </Text>
                    </TableHeaderWithTooltip>
                </TableRowCell>
            ),
            cell: info => {
                const rankingData = info.renderValue();
                return rankingData !== null ? (
                    <TableValue traderColumn={info.row.getValue("trader")} pr={2}>
                        <LeaderBoardFigureValue value={rankingData} type={EnumLeaderBoardFigureValueType.Herfindahl} />
                    </TableValue>
                ) : null;
            },
        }),
    ];

    return columns;
};
