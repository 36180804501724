import { Box, HStack } from "@chakra-ui/react";
import { Button, ButtonLink } from "src/components/base/button";
import { WfHeading } from "src/components/base/wf-heading";
import { RichText } from "src/components/common/rich-text/rich-text";
import { IconSuccess } from "src/components/icons";
import { Container } from "src/components/layout/container";

interface IChallengeFormSuccessMessageProps {
    heading: string;
    subHeading: string;
    content: string;
    firstButtonLabel: string;
    firstButtonUrl: string;
    secondButtonLabel?: string | null;
    secondButtonUrl?: string;
    registrationFormLabel: string;
    onRegistrationFormClick: () => void;
}

export const ChallengeFormSuccessMessage = ({
    heading,
    subHeading,
    content,
    firstButtonUrl,
    firstButtonLabel,
    secondButtonUrl,
    secondButtonLabel,
    registrationFormLabel,
    onRegistrationFormClick,
}: IChallengeFormSuccessMessageProps) => {
    const isSecondButtonButtonSpecified = Boolean(secondButtonLabel && secondButtonUrl);

    const handleRegistrationFormClick = () => {
        onRegistrationFormClick();
    };
    return (
        <Container textAlign="center">
            <WfHeading as="h1" fontSize="4xl">
                {heading}
            </WfHeading>
            <RichText mt={2} fontSize="lg" text={subHeading} />
            <IconSuccess fill="transparent" mt={7} mb={4} w="130px" h="130px" />
            <Box w="100%" maxWidth="570px" mx="auto" mb={4} textAlign="left">
                <RichText text={content} sx={{ ul: { listStyle: "disc", ml: 3 } }} />
            </Box>
            <HStack justifyContent="center" wrap="wrap" spacing={2}>
                {isSecondButtonButtonSpecified ? (
                    <Button w={["100%", "auto"]} variant="outline" as="a" href={secondButtonUrl}>
                        {secondButtonLabel}
                    </Button>
                ) : undefined}
                <Button
                    w={["100%", "auto"]}
                    variant={isSecondButtonButtonSpecified ? undefined : "outline"}
                    colorScheme={!isSecondButtonButtonSpecified ? undefined : "green"}
                    as="a"
                    href={firstButtonUrl}
                >
                    {firstButtonLabel}
                </Button>
            </HStack>
            <Box mt={4}>
                <ButtonLink onClick={handleRegistrationFormClick}>{registrationFormLabel}</ButtonLink>
            </Box>
        </Container>
    );
};
