import { useRef, useState } from "react";
import React from "react";
import { Box, Checkbox, Flex, HStack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useChallengeFormMutation } from "src/api/client/challenge-form.api";
import { Button } from "src/components/base/button";
import { FilledCircle } from "src/components/base/filled-circle";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { RichText } from "src/components/common/rich-text/rich-text";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { Container } from "src/components/layout/container";
import { WfAlertDialog } from "src/components/modals/wf-alert-dialog/wf-alert-dialog";
import { IChallengeOrganisation, TChallengeWikifolio } from "src/components/modules/challenge-form-module/challenge-form-module.types";
import { ChallengeDate } from "src/components/modules/challenge-form-module/components/challenge-date";
import { ChallengeDropdown } from "src/components/modules/challenge-form-module/components/challenge-dropdown";
import { ChallengeFormSuccessMessage } from "src/components/modules/challenge-form-module/components/challenge-form-success-message";
import { replaceClubWikifolioTextVariables } from "src/components/modules/challenge-form-module/utils/replace-club-wikifolio-text-variables";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { EnumToastStatus, useWfToast } from "src/hooks/use-wf-toast";
import { ILink } from "src/types/common";

export interface IChallengeFormModuleProps {
    title: string;
    subline: string;
    hasUserParticipated: boolean;
    validUntilDate: string;
    dropdown1Title: string;
    dropdown1Description: string;
    dropdown2Title: string;
    dropdown2Description: string;
    participationTermsLabel: string;
    mandatoryFieldMarkLabel: string;
    registerButton: string;
    formChallengeData: {
        availableOrganisations: IChallengeOrganisation[];
        availableWikifolios: TChallengeWikifolio[];
        challengeId: string;
        selectedOrganisation?: IChallengeOrganisation;
        selectedWikifolio?: TChallengeWikifolio;
        hasUserParticipated: boolean;
        registrationStartDate: string;
        registrationEndDate: string;
        isRegistrationActive: boolean;
    } | null;
    confirmationModal: {
        title: string;
        description: string;
    };
    successState: {
        title: string;
        subline: string;
        description: string;
        toRegistrationForm: string;
        toLeaderboardLink: ILink;
    };
}

export const ChallengeFormModule = ({
    title,
    subline,
    dropdown1Title,
    dropdown1Description,
    dropdown2Title,
    dropdown2Description,
    participationTermsLabel,
    mandatoryFieldMarkLabel,
    formChallengeData,
    confirmationModal,
    successState,
    registerButton,
}: IChallengeFormModuleProps) => {
    const { t } = useTranslation(["challenge-form-module", "common"]);
    const [selectedWikifolio, setSelectedWikifolio] = useState<TChallengeWikifolio | undefined>(formChallengeData?.selectedWikifolio);
    const [selectedOrganisation, setSelectedOrganisation] = useState<IChallengeOrganisation | undefined>(formChallengeData?.selectedOrganisation);
    const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(formChallengeData?.hasUserParticipated ?? false);
    const confirmDialog = useDisclosure();
    const { buildWikifolioLink } = useLinkBuilder();
    const toast = useWfToast();

    const challengeFormRef = useRef<HTMLDivElement>(null);
    const challengeFormMutation = useChallengeFormMutation(formChallengeData?.challengeId ?? "");

    const isRegistrationActive = Boolean(formChallengeData?.isRegistrationActive);
    const isLastPeriodOngoing = formChallengeData === null;

    const handleFormSubmit = () => {
        confirmDialog.onClose();
        challengeFormMutation
            .mutatePromise({ wikifolioId: selectedWikifolio!.id, organisationId: selectedOrganisation!.id })
            .then(() => {
                setIsFormSubmitted(true);
                setIsCheckboxSelected(false);
            })
            .catch(() => {
                toast({ status: EnumToastStatus.Error, title: t("general-error", { ns: "common" }) });
            });
    };

    const isSubmitButtonDisabled = [
        !selectedWikifolio,
        !selectedOrganisation,
        !isCheckboxSelected,
        !isRegistrationActive,
        challengeFormMutation.isLoading,
    ].some(Boolean);

    const scrollToTheModule = () => {
        if (challengeFormRef.current) {
            challengeFormRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    const handleRegistrationFormClick = () => {
        setIsFormSubmitted(false);
        scrollToTheModule();
    };

    return (
        <React.StrictMode>
            <>
                <Box ref={challengeFormRef}></Box>
                {isFormSubmitted ? (
                    <ChallengeFormSuccessMessage
                        heading={successState.title}
                        subHeading={replaceClubWikifolioTextVariables(
                            successState.subline,
                            selectedWikifolio!.shortDescription,
                            selectedOrganisation!.name
                        )}
                        firstButtonLabel={t("to-wikifolio", { ns: "common" })}
                        firstButtonUrl={buildWikifolioLink(selectedWikifolio!.symbol)}
                        secondButtonLabel={successState?.toLeaderboardLink?.name}
                        secondButtonUrl={successState?.toLeaderboardLink?.url}
                        content={successState.description}
                        registrationFormLabel={successState.toRegistrationForm}
                        onRegistrationFormClick={handleRegistrationFormClick}
                    />
                ) : (
                    <Container my={10}>
                        <VStack justifyContent="center" textAlign="center">
                            <ModulePrimaryHeadline>{title}</ModulePrimaryHeadline>

                            <RichText mt={2} fontSize="lg" maxWidth="862px" text={subline} />
                            <HStack mt={2} spacing={1} alignItems="baseline" w="100%" justifyContent="center">
                                <FilledCircle
                                    position="relative"
                                    top="2px"
                                    flexShrink="0"
                                    size={2}
                                    color={isRegistrationActive ? "green.600" : "gray.300"}
                                />
                                <ChallengeDate
                                    isRegistrationActive={isRegistrationActive}
                                    isLastPeriodOngoing={isLastPeriodOngoing}
                                    registrationStartDate={formChallengeData?.registrationStartDate || ""}
                                    registrationEndDate={formChallengeData?.registrationEndDate || ""}
                                />
                            </HStack>
                            <Box w="100%" maxWidth="620px" mt={5} textAlign="left">
                                <Box>
                                    <Text fontSize="lg" fontWeight="bold">
                                        {dropdown1Title}
                                    </Text>
                                    <Text mb={2} mt={1} fontSize="sm" color="gray.600">
                                        {dropdown1Description}
                                    </Text>
                                    <ChallengeDropdown
                                        isDisabled={!isRegistrationActive}
                                        options={formChallengeData?.availableWikifolios}
                                        value={selectedWikifolio}
                                        onChange={val => setSelectedWikifolio(val as TChallengeWikifolio)}
                                        placeholder={t("select.placeholder")}
                                        emptyText={t("select.no-wikifolios-found")}
                                    />
                                </Box>
                                <Box mt={4}>
                                    <Text fontSize="lg" fontWeight="bold">
                                        {dropdown2Title}
                                    </Text>
                                    <Text mb={2} mt={1} fontSize="sm" color="gray.600">
                                        {dropdown2Description}
                                    </Text>
                                    <ChallengeDropdown
                                        isDisabled={!isRegistrationActive}
                                        options={formChallengeData?.availableOrganisations}
                                        value={selectedOrganisation}
                                        onChange={val => setSelectedOrganisation(val as IChallengeOrganisation)}
                                        placeholder={t("select.placeholder")}
                                    />
                                </Box>
                                <Flex>
                                    <Checkbox
                                        alignItems="unset"
                                        isDisabled={!isRegistrationActive}
                                        mt={4}
                                        onChange={e => setIsCheckboxSelected(e.target.checked)}
                                    >
                                        <SimpleRichText text={participationTermsLabel} />
                                    </Checkbox>
                                </Flex>
                                <RichText fontSize="md" my={4} text={mandatoryFieldMarkLabel} />
                                <Button
                                    onClick={confirmDialog.onOpen}
                                    colorScheme="green"
                                    width={["100%", "unset"]}
                                    isDisabled={isSubmitButtonDisabled}
                                >
                                    {registerButton}
                                </Button>
                            </Box>
                        </VStack>
                        <WfAlertDialog
                            {...confirmDialog}
                            onConfirm={handleFormSubmit}
                            confirmButtonText={t("buttons.confirm")}
                            cancelButtonText={t("buttons.cancel")}
                            header={confirmationModal.title}
                            body={
                                <Text
                                    whiteSpace="pre-line"
                                    dangerouslySetInnerHTML={{
                                        __html: replaceClubWikifolioTextVariables(
                                            confirmationModal.description,
                                            selectedWikifolio?.shortDescription,
                                            selectedOrganisation?.name
                                        ),
                                    }}
                                />
                            }
                        />
                    </Container>
                )}
            </>
        </React.StrictMode>
    );
};
